body {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
html {
  /* overflow-y: scroll; */
}
.bold { font-weight: 700; }
/*a:hover { text-decoration: none; color: #0a3857;}*/
.breadcrumb {
padding: 8px 0!important;
}
legend {
padding-bottom: 10px;
margin-bottom: 20px;
font-size: 20px;
font-weight: 700;
line-height: inherit;
color: #333;
border-bottom: 1px solid #dfe0e1;
}

.greenlink a { color:#36c157; }
.greenlink a:hover { color:#2e9e49; }
.redlink a { color:#da4830; }
.redlink { color:#da4830; }
.redlink a:hover { color:#c13b25; }
.redlink:hover { color:#c13b25; }

.buttons { margin: 25px 0;  }
.buttons .btn { margin: 0 6px;  }

/*forms*/
.form-group {
margin-bottom: 17px;
}
.form-control {
display: block;
width: 100%;
height: 40px;
padding: 9px 12px;
font-size: 16px;
line-height: 1.42857143;
color: #000 !important;
background: #f9f9f9 !important;
background-image: none;
border: 1px solid #dfe0e1;
border-radius: 2px;
-webkit-box-shadow: none;
box-shadow: none;
-webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-horizontal .control-label, .form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
margin-top: 0;
margin-bottom: 0;
padding-top: 10px;
}
.form-control-static {
    padding-top: 11px;
}
textarea.form-control {
    /*height: auto !important;*/
    min-height: 40px;
}
/*tables*/
table.data-table td {
    height: 38px !important;
}

table.dataTable { border-radius: 3px; border-collapse: collapse;
    /*border-spacing: 0;*/}

/*
table.dataTable tr:hover {
    background-color: #F2F5FE !important;
}
*/
th:first-child {
    border-radius: 3px 0 0 0;
    border-left: none;
}
th:last-child {
    border-radius: 0 3px 0 0;
}

tr {border: none;}
tbody td {border-left: 1px solid #FFFFFF;}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
vertical-align: middle;
border-top: none;
}
table.invoice-table>thead>tr>th, table.invoice-table>tbody>tr>th, table.invoice-table>tfoot>tr>th, table.invoice-table>thead>tr>td, table.invoice-table>tbody>tr>td, table.invoice-table>tfoot>tr>td {
border-bottom: 1px solid #dfe0e1;
}
table.dataTable.no-footer {
border-bottom: none;
}
.table-striped>tbody>tr:nth-child(odd)>tr,
.table-striped>tbody>tr:nth-child(odd)>th {
background-color: #FDFDFD;
}
table.table thead .sorting_asc {
background: url('../images/sort_asc.png') no-repeat 90% 50%;
}
table.table thead .sorting_desc {
background: url('../images/sort_desc.png') no-repeat 90% 50%;
}
table.dataTable thead th, table.dataTable thead td, table.invoice-table thead th, table.invoice-table thead td {
padding: 12px 10px;
}
table.dataTable tbody th, table.dataTable tbody td {
padding: 10px;
}

table.data-table tr {
    border-bottom: 1px solid #d0d0d0;
    border-top: 1px solid #d0d0d0;
}

.datepicker {
padding: 4px !important;
margin-top: 1px;
-webkit-border-radius: 3px;
-moz-border-radius: 3px;
border-radius: 3px;
}
.datepicker.dropdown-menu {
border: 1px solid #dfe0e1;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
color: #333333;
font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
font-size: 13px;
line-height: 20px;
}
.datepicker table {font-size: 12px; border-spacing:2px;}
.datepicker td, .datepicker th { width:30px; }
.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active {
background-color: #337ab7;
    background-image:none;
}
.datepicker table tr td.today { color: #333; background-color: #edd71e !important; background-image:none; text-shadow:none;}
.datepicker table tr td.today:hover { color: #333; background-color: #edd71e !important; background-image:none; text-shadow:none;}
.datepicker table tr td.today.active:hover {
color: #333;
}

/*modals*/
.modal .container {
padding: 20px;
}
.modal-header {
border-bottom: none;
background-color: #337ab7;
    padding: 20px;
    color: #fff;
}
.modal-footer {
background-color: #f8f8f8;
border-top: none;
}
.modal thead {
background: #fff;
color: #333;
}
.modal .table>thead>tr>th {
border-bottom: 1px solid #dfe0e1 !important; padding-top: 30px;
    background: #fff !important;
    color: #333 !important;
}
.modal .table>thead>tr>th:first-child, .modal .table>thead>tr>th:last-child {
border-bottom: none !important;
}
.modal .close {
color: #fff;
text-shadow: none;
opacity: .8;
filter: alpha(opacity=80);
}
.modal .close:hover {
opacity: 1;
filter: alpha(opacity=100);
}
/*buttons*/
.btn { font-weight: bold;
    border-radius: 3px;
    padding: 9px 12px;
}
.btn-success {
background-color: #36c157 !important;
border-color: #36c157 !important;
}
.btn-success:hover,.btn-success:focus,.btn-success:active,.btn-success.active,.open .dropdown-toggle.btn-success{background-color:#33b753 !important; border-color:#33b753 !important;}
.btn-sm, .btn-group-sm>.btn {
padding: 5px 10px;
}
.btn-group.open .dropdown-toggle {
-webkit-box-shadow: none;
box-shadow: none;
}
/*
.xbtn-primary {
background-color: #34495E;
border-color: #34495E;
}
.xbtn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
background-color: #0a456c;
border-color: #0a456c;
}
*/
.btn-default {background-color: #808080;
border-color: #808080;
    color: #fff;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
color: #fff;
background-color: #737373;
border-color: #737373;
}
.btn-info {background-color: #e27329;
border-color: #e27329;
    color: #fff;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
color: #fff;
background-color: #d66d27;
border-color: #d66d27;
}
.btn-lg, .btn-group-lg>.btn {
padding: 10px 16px;
font-size: 18px;
    height: auto;
}
.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default[disabled].active, fieldset[disabled] .btn-default.active {
background-color: #b5b5b5;
border-color: #b5b5b5;
}
.input-group-addon {
background-color: #f4f4f4;
border: 1px solid #dfe0e1;
border-radius: 3px;
    cursor:pointer;
}
.caret {
margin-left: 0px;
}
.btn i.glyphicon {  font-size: 16px; margin-left:7px; top: 2px; }

.btn-primary i{
xborder-color: #337ab7;
}

.form-actions .btn,
.form-actions div.btn-group {
    margin-left: 10px;
}

.form-actions .btn.btn-success:first-child {
margin-left: 10px !important;
}

/*alerts*/

.alert {
padding: 15px;
border: none;
border-radius: 3px;
}

/*new*/

div.input-group {
    word-break: normal;
}

div.required > label {
    font-weight: bold !important;
}

label.checkbox,
label.control-label {
    font-weight: normal !important;
}

.breadcrumb {
    background-color: inherit;
    font-size: 22px;
}

div.panel {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.panel {
    box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 1px 1px rgba(0,0,0,.2);
    border-color: #ffffff !important;
}

.pointer {
    cursor: pointer;
}

.form-actions {
    margin: 0;
    background-color: transparent;
    text-align: center;
}

.less-space-bottom {
    padding-bottom: 4px !important;
    margin-bottom: 4px !important;
}

/* DataTables and BootStrap */
.dataTables_wrapper {
    padding-top: 16px;
}

table.table thead > tr > th {
    border-bottom-width: 0px;
}

table td {
    max-width: 250px;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
border-bottom-left-radius: 3px;
border-top-left-radius: 3px;
}


.dt-right,
.dt-left {
    margin-top: 16px;
}
.pagination>ul {
    margin: 0px;
}

/* hide table sorting indicators */
table.table thead .sorting { background: url('') no-repeat center right; }



/* navigation */
.sidebar-nav {
    padding: 9px 0;
}
.dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    visibility: hidden;
}

.dropdown-menu li:hover .sub-menu {
    visibility: visible;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.navbar-nav>li>a {
padding-top: 20px;
padding-bottom: 20px;
}
.nav-tabs .dropdown-menu, .nav-pills .dropdown-menu, .navbar .dropdown-menu {
    margin-top: 0;
}

.nav-tabs { color:#fff; }
.nav-tabs.nav-justified>li>a {
border: none;
border-radius: 0;
color: #fff;
background-color: #9b9b9b;

}
.nav-tabs.nav-justified>li:first-child>a {
    border-radius: 3px 0 0 3px;
    border-left: none;
}
.nav-tabs.nav-justified>li:last-child>a {
    border-radius: 0 3px 3px 0;
}
.nav-tabs.nav-justified>li>a:hover {
    background-color:#8a8a8a;
}
.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:focus {
    border: none;
    font-weight: bold;
    color: #fff;
}
.navbar {
  xbackground-color: #337ab7 !important;
  background-image: none;
  background-repeat: no-repeat;
  filter: none;
}

.navbar-collapse {
    xbackground-color: #337ab7;
}

.navbar,
ul.dropdown-menu,
.twitter-typeahead .tt-menu {
    x-moz-box-shadow: 0 0 10px 2px rgba(0,0,0,.05);
    x-webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.05);
    box-shadow: 0 0 10px 2px rgba(0,0,0,.05);
}

.twitter-typeahead .tt-menu {
    overflow-x: hidden;
}

.panel-default,
canvas {
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 3px;
}

.navbar .active > a {
  background-color: #09334f !important;
  background-image: none;
  background-repeat: no-repeat;
  filter: none;
}

.navbar .sub-menu:before {
    border-bottom: 7px solid transparent;
    border-left: none;
    border-right: 7px solid rgba(0, 0, 0, 0.2);
    border-top: 7px solid transparent;
    left: -7px;
    top: 10px;
}
.navbar .sub-menu:after {
    border-top: 6px solid transparent;
    border-left: none;
    border-right: 6px solid #fff;
    border-bottom: 6px solid transparent;
    left: 10px;
    top: 11px;
    left: -6px;
}
.navbar-brand {
padding-top:20px;
}
.dropdown-menu {
left: 0;
top: 100%;
min-width: 160px;
padding: 5px 0;
font-size: 14px;
border: none;
border-radius: 3px;
-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.05);
box-shadow: 0 6px 12px rgba(0,0,0,.05);
background-clip: padding-box;
}


/***********************************************
   Dashboard
************************************************/

.in-bold {
    font-size: 26px;
    font-weight: bold;;
}


.in-thin {
    font-size: 26px;
    font-weight: 100;
}

.in-bold-white {
    font-weight: bold;
    color: white;
}

.in-image {
    float: left;
    margin-right: 25px;
}

.in-white {
    color: white;
}


.active-clients {
    background-image:url('../images/activeclients.png');
    background-position:center;
    background-repeat: no-repeat;
    height: 200px;
    padding-top: 44px;
    text-align: center;
}

.average-invoice {
    background-color: #ecd817;
    min-height: 200px;
    padding-top: 60px;
    text-align: center;
}

.invoice-table tbody {
    border-style: none !important;
}
.panel-body {padding: 25px;}

.dashboard .panel-heading { margin: -1px; }

.dashboard .panel-body {padding: 0;}

.dashboard th {
border-left: none;
    background-color: #fbfbfb;
    border-bottom: 1px solid #dfe0e1;
}

.dashboard table.table thead > tr > th {
border-bottom-width: 1px;
}

.dashboard .table-striped>tbody>tr>td:first-child { padding-left: 15px;  }
.dashboard .table-striped>thead>tr>th:first-child { padding-left: 15px;  }


.invoice-table tfoot input {
    text-align: right;
}


/***********************************************
   New/edit invoice page
************************************************/

table.invoice-table { color:#333; }

table.invoice-table th:first-child {
    border-radius: 3px 0 0 3px;
}
table.invoice-table th:last-child {
    border-radius: 0 3px 3px 0;
}

.invoice-table td.hide-border,
.invoice-table th.hide-border {
    border-style: none !important;
}

.invoice-table .line-total {
    padding-top: 6px;
}


.invoice-table td.td-icon {
    vertical-align: middle !important;
}

.fa-sort {
    cursor: move !important;
}

.closer-row {
    margin-bottom: 2px;
}


/* Animate col width changes */
/*
body {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
*/

div.discount-group span {
    padding: 0px;
    border: none;
}

#is_amount_discount {
    min-width: 120px;
}

/***********************************************
   New/edit invoice page
************************************************/

.two-column .form-group div {
	-webkit-column-count:2; /* Chrome, Safari, Opera */
	-moz-column-count:2; /* Firefox */
	column-count:2;
}

.two-column .form-group div .radio {
	margin-left:10px;
}

/***********************************************
   Add mouse over drop down to header menu
************************************************/

.navbar-default {
    background-color: #428bff;
    border-color: transparent;
}
.navbar-default .navbar-brand {
    color: #ecf0f1;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #ffffff;
}
.navbar-default .navbar-nav > li > a {
    color: #ecf0f1;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #ffffff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #ffffff;
    background-color: #3276b1;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #ffffff;
    background-color: #3276b1;
}
.navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #ecf0f1;
    border-bottom-color: #ecf0f1;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.navbar-default .navbar-toggle {
    border-color: #3276b1;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #3276b1;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #ecf0f1;
}
.navbar-form { margin-top: 15px; padding-right:0; }
.navbar-form:first-child { padding-right: 0; }
.navbar-form .form-control { height: 30px; }
.twitter-typeahead .tt-hint {
height: 30px;
border-radius: 3px;
}
.navbar-form .btn-default {
color: #fff;
background-color: #09334f;
border-color: #09334f;
}
.navbar-form .dropdown-toggle.btn-default {
color: #fff;
background-color: #08273c;
border-color: #08273c;
}
#signUpPopOver {
    cursor: pointer;
}
div.fb_iframe_widget {
    display: inline;
}
div.fb_iframe_widget > span {
    vertical-align: top !important;
}
.pro-label {
    font-size:9px;
}


.plans-table {float: none; margin-top: 10px; }
.plans-table div {text-align:center; margin: 0 auto; }

.plans-table .free, .plans-table .desc { padding: 0; }
.plans-table .free .cell { padding-right: 15px; }
.plans-table .desc .cell { text-align: right; padding-right: 15px; border-left: 1px solid #dfe0e1; font-size: 13px; font-weight: 800; }
.plans-table .pro .cell { border-left: 1px solid #cccccc; border-right: 1px solid #cccccc;}


.plans-table .cell {background-color: #fff; border-top: 1px solid #dfe0e1;padding: 18px 0; font-family: Roboto, sans-serif; height: 60px;}
.plans-table .cell:nth-child(odd){background-color: #fbfbfb;}
.plans-table .pro .cell:nth-child(odd){background-color: #f4f4f4;}
.plans-table .pro {
    background-color: #2299c0;
     overflow:hidden;
    padding: 0;
-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 5, 5, 0.2);
-moz-box-shadow:    0px 0px 15px 0px rgba(0, 5, 5, 0.2);
box-shadow:         0px 0px 15px 0px rgba(0, 5, 5, 0.2);
}

.plans-table .free .cell:first-child, .plans-table .pro .cell:first-child {color: #fff; text-transform: uppercase; font-size: 24px; font-weight:800; line-height: 60px; padding: 0; position: relative; bottom: -1px; border: none;}
.plans-table .free .cell:first-child {background-color: #9b9b9b; margin-right: 15px; padding-right: 0;}
.plans-table .free, .plans-table .desc {border-bottom: 1px solid #dfe0e1;}
.plans-table .pro .cell:first-child {background-color: #2299c0;}
.plans-table .pro .cell:last-child {padding: 0; border: none;}
.plans-table .desc .cell:first-child {background-color: transparent; border: none;}

.plans-table .glyphicon {color: #fff; border-radius: 50px; padding: 5px; font-size: 10px;}
.plans-table .glyphicon-remove {background-color: #da4830;}
.plans-table .glyphicon-ok {background-color: #35c156;}
.plans-table .glyphicon-star {border-radius: 0; background-color: #2e2b2b;
    display: block;
    width: 60px;
    height: 30px;
    position: absolute;
    top: -5px;
    right: -20px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 13px 0 0 1px;
}

.plans-table .price {padding: 0; }
.plans-table .free .price p {color: #35c156;}
.plans-table .pro .price p {color: #2299c0;}
.plans-table .price p {font-size: 40px; text-transform: uppercase; font-weight: 800; margin: 0; line-height: 55px;}
.plans-table .price p span {font-size: 16px; text-transform: none; font-weight: 400;}

.plans-table a .cta h2 {background: #2299c0; color:#fff; margin: 0;}
.plans-table a .cta h2 span {background: #1e84a5;}


.checkbox-inline input[type="checkbox"] {
  margin-left: 0px !important;
}


#designThumbs img {
    border: 1px solid #CCCCCC;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.entityArchived {
    color: #888 !important;
}

.entityDeleted {
    text-decoration: line-through;
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
   .form-padding-right {
        padding-right: 40px;
   }

   .hide-non-phone {
       display: none;
   }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
   .form-padding-right {
        padding-right: 100px;
   }
   .medium-dialog {
        width: 760px;
   }
   .large-dialog {
        width: 960px !important;
   }
   .hide-desktop {
        display: none;
   }
}

@media (max-width: 767px) {
    .test-class{color:black;}

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #ecf0f1;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ffffff;
    }

    .plans-table .cell {height: auto; padding: 14px 0; }
    .plans-table .free .cell { padding-right: 0; }
    .plans-table .free .cell:first-child {margin-right: 0;}
    .plans-table .cell div:first-child {margin-bottom: 5px;}
    .plans-table .cell .cta {margin-bottom: 0 !important;}
    .plans-table .pro {margin-top: 40px;}

    .hide-phone {
        display: none;
    }
}

label[for=recommendedGateway_id2].radio{
    min-height: 60px;
}

/* Hide bootstrap sort header icons */
table.table thead .sorting:after { content: '' !important }
table.table thead .sorting_asc:after { content: '' !important }
table.table thead .sorting_desc:after { content: '' !important}
table.table thead .sorting_asc_disabled:after { content: '' !important }
table.table thead .sorting_desc_disabled:after { content: '' !important }

/* Prevent modal from shifting page a bit - https://github.com/twbs/bootstrap/issues/9886 */
body.modal-open { overflow:inherit; padding-right:inherit !important; }


/* bootstrap 3.2.0 fix */
/* https://github.com/twbs/bootstrap/issues/13984 */
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    margin-left: 0;
    padding-left: 0px !important;
    margin-right: 5px;
    height: inherit;
    width: inherit;
    float: left;
    display: inline-block;
    position: relative;
    margin-top: 3px;
}

div.checkbox > label {
    padding-left: 0px !important;
}

.container input[type=text],
.container input[type=email],
.container textarea,
.container select {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    color: #000 !important;
    background: #f9f9f9 !important;
    /*border: 1px solid #ebe7e7;*/
    border-radius: 3px;
}

.container input:focus,
.container textarea:focus,
.container select:focus {
    background: #fdfdfd !important;
}

.container input[placeholder],
.container textarea[placeholder],
.container select[placeholder] {
   color: #444444;
}

.container input:disabled,
.container textarea:disabled,
.container select:disabled {
    background-color: #EEE !important;
}

.panel-title {
    font-size: 18px;
    color: white;
}

div.alert {
    z-index: 1;
}

.alert-hide {
  position: absolute;
  margin-left: 40%;
  z-index: 9999;
}

div.dataTables_length {
    padding-left: 20px;
    padding-top: 10px;
}

div.dataTables_length select {
    background-color: white !important;
}

div.dataTables_length label {
    font-weight: 500;
}

a .glyphicon,
button .glyphicon {
    padding-left: 12px;
}

.pro-plan-modal {
    background-color: #4b4b4b;
    padding-bottom: 40px;
    padding-right: 25px;
    opacity:0.95 !important;
}

.pro-plan-modal .left-side {
    margin-top: 50px;
}

.pro-plan-modal h2 {
    color: #36c157;
    font-size: 71px;
    font-weight: 800;
}

.pro-plan-modal img.price {
    height: 90px;
}

.pro-plan-modal a.button {
    font-family: 'roboto_slabregular', Georgia, Times, serif;
    background: #f38c4f;
    background: -moz-linear-gradient(top,  #f38c4f 0%, #db7134 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f38c4f), color-stop(100%,#db7134));
    background: -webkit-linear-gradient(top,  #f38c4f 0%,#db7134 100%);
    background: -o-linear-gradient(top,  #f38c4f 0%,#db7134 100%);
    background: -ms-linear-gradient(top,  #f38c4f 0%,#db7134 100%);
    background: linear-gradient(to bottom,  #f38c4f 0%,#db7134 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f38c4f', endColorstr='#db7134',GradientType=0 );
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
    width: 68%;
    margin-top: 20px;
    font-size: 28px;
    color: #fff;
    border-radius: 10px;
    padding: 20px 0;
    display: inline-block;
    text-decoration: none;
}

.pro-plan-modal a.button:hover {
    background: #db7134; /* Old browsers */
    background: -moz-linear-gradient(top,  #db7134 0%, #f38c4f 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#db7134), color-stop(100%,#f38c4f)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #db7134 0%,#f38c4f 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #db7134 0%,#f38c4f 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #db7134 0%,#f38c4f 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #db7134 0%,#f38c4f 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#db7134', endColorstr='#f38c4f',GradientType=0 ); /* IE6-9 */
}


.pro-plan-modal ul {
    color: #fff;
    list-style: none;
    padding: 0 0 30px 0;
    text-align: left;
    white-space: pre-line;
    margin: 0;
}

.pro-plan-modal ul li {
    font-family: 'roboto_slabregular', Georgia, Times, serif;
    background: url('../images/pro_plan/check.png') no-repeat 0px 12px;
    display: inline-block;
    font-size: 17px;
    line-height: 36px;
    padding: 0 0 0 19px;
}

.pro-plan-modal img.close {
    width: 35px;
    margin-top: 20px;
}

ul.user-accounts div.account {
    font-size: large;
}

ul.user-accounts div.remove {
    padding-top: 14px;
    color: #BBB;
    visibility: hidden;
}

ul.user-accounts a:hover div.remove {
    visibility: visible;
}

.invoice-contact {
    word-break: break-all;
}

.invoice-contact .tooltip-inner {
    text-align:left;
    width: 350px;
}

.smaller {
    font-size: .9em;
}

td.right {
  text-align: right;
}

/* Show selected section in settings nav */
.list-group-item.selected:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
    content: "";
    background-color: #e37329;
}

div.panel-body div.panel-body {
    padding-bottom: 0px;
}

/* Attached Documents */
#document-upload {
    border:1px solid #ebe7e7;
    background:#f9f9f9 !important;
    border-radius:3px;
    padding:20px;
}

.invoice-table #document-upload{
    width:500px;
}

#document-upload .dropzone{
    background:none;
    border:none;
    padding:0;
}

.dropzone .dz-preview.dz-image-preview{
    background:none;
}

.dropzone .dz-preview  .dz-image{
    border-radius:5px!important;
}

.dropzone .dz-preview.dz-image-preview .dz-image img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}


/* Limits */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.noUi-target {
    position: relative;
    direction: ltr;
    margin:0 17px;
}
.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; /* Fix 401 */
}
.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}
.noUi-handle {
    position: relative;
    z-index: 1;
}
.noUi-stacking .noUi-handle {
    /* This class is applied to the lower origin when
       its values is > 50%. */
    z-index: 10;
}
.noUi-state-tap .noUi-origin {
    -webkit-transition: left 0.3s, top 0.3s;
    transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
    cursor: inherit !important;
}
.noUi-base,
.noUi-handle {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.noUi-horizontal {
    height: 15px;
}
.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 25px;
    left: -17px;
    top: -6px;
}

.noUi-background {
    background: #f9f9f9;
}
.noUi-connect {
    background: #286090;
}
.noUi-origin {
    border-radius: 2px;
}
.noUi-target {
    border-radius: 4px;
    border: 1px solid #ddd;
}

.noUi-draggable {
    cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
    cursor: n-resize;
}
.noUi-handle {
    border: 1px solid #777;
    border-radius: 3px;
    background: #FFF;
    cursor: pointer;
}

.noUi-handle:before,
.noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 13px;
    width: 1px;
    background: #777;
    left: 14px;
    top: 5px;
}
.noUi-handle:after {
    left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}
.noUi-vertical .noUi-handle:after {
    top: 17px;
}

#payment-limits-slider{
    margin-bottom:10px;
}

#payment-limit-min-container .input-group,
#payment-limit-max-container .input-group{
    max-width:200px;
    clear:both;
}

#payment-limit-max-container{
    text-align:right;
}

#payment-limit-max-container .input-group,
#payment-limit-max-container label{
    float:right;
}

#payment-limit-min[disabled],
#payment-limit-max[disabled]{
    background-color: #eee!important;
    color: #999!important;
}

.breadcrumb .label {
  vertical-align: middle;
  margin-left: 16px;
}


/*
 * BlueVine Promo
 */
@media (min-width: 900px) {
    #bluevineModal .modal-dialog{
        width:800px;
    }
}

.bluevine-quote .row{
    text-align: right;
}

.select2-selection {
    border: 1px solid #dfe0e1 !important;
    border-radius: 2px;
    padding: 2px;
}

.modal-content {
    background-color: #f8f8f8;
}

.modal-footer {
    padding-right: 20px;
}
